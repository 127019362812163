<template>
  <div class="user">
    <!-- 书签 -->
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/aiHome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/aiUser/list' }">用户列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ breadcrumb }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumb: '新增',
      userForm: {
        shop_user_id: ''
      }
    }
  },
  methods: {
    async init() {
      console.log(this.userForm.shop_user_id)
    }
  },
  mounted() {
    this.init()
  },
  created() {
    this.userForm.shop_user_id = this.$route.query.shop_user_id
  }
}
</script>

<style lang="less" scoped>
.user {
  padding: 10px;
  .title {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
}
</style>